// MenuItem.tsx

import React, { useState } from 'react';
import {
  Button,
  Menu,
  MenuItem as MuiMenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

interface MenuItemProps {
  item: { id: string; text: string };
  onDelete: (itemId: string) => void;
  onSelect: (itemId: string) => void;
  onEdit: (itemId: string, newText: string) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ item, onDelete, onEdit,onSelect }) => {
  const [isEditing, setEditing] = useState(false);
  const [editedText, setEditedText] = useState(item.text);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isActive,setIsActive] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
    handleCloseMenu();
  };

  const handleEdit = () => {
    setEditing(true);
    handleCloseMenu();
  };

  const handleSave = () => {
    //alert("editedText" + editedText)
    onEdit(item.id, editedText);
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setEditedText(item.text);
  };

  const handleDeleteConfirm = () => {
    onDelete(item.id);
    setDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleSelect =() => {
    setIsActive(true);
    onSelect(item.id);
  }

  return (
    <div>
      {isEditing ? (
        <div>
          <TextField style={{height:'60px',padding:'0px',width:'180px'}}
            label="Edit Title"
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
          />
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      ) : (
        <div className={`conversation-title-box ${localStorage.getItem('conversationId')==item.id ? 'active' : ''}`}>
          <span className={`conversation-title ${isActive ? 'active' : ''}`} onClick={handleSelect}>{item.text}</span>
          <span className="edit-button" onClick={handleOpenMenu}>...</span>
        </div>
      )}

      {/* Ellipsis Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MuiMenuItem onClick={handleEdit}>Edit</MuiMenuItem>
        <MuiMenuItem onClick={handleDelete}>Delete</MuiMenuItem>
      </Menu>

      {/* Delete Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MenuItem;
