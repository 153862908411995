import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {PublicClientApplication,EventType} from '@azure/msal-browser'
import reportWebVitals from './reportWebVitals';
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

if(!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0)
{
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

msalInstance.addEventCallback((event) => {
  if(event.eventType === EventType.LOGIN_SUCCESS && event.payload.account)
  {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App instance={msalInstance}/>
  // </React.StrictMode>
);

/*
const root = ReactDOM.createRoot(document.getElementById('root'));
 root.render(
  <App/>
) */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
