// LoginPage.js

import React from 'react';
//import { useHistory } from 'react-router-dom';
import { Button, Container, CssBaseline, Typography } from '@mui/material';
import { loginRequest } from './authConfig';
import { useMsal } from '@azure/msal-react';

const LoginPage = () => {
  //const history = useHistory();
  const {instance} = useMsal();

  const handleLogin = () => {
    // Implement your authentication logic here
    // For simplicity, we'll just redirect to the dashboard
    //history.push('/dashboard');
    instance
    .loginRedirect({
      ...loginRequest,
      prompt:'create'
    })
    .catch((error) => console.log(error));
  };

  return (
    <Container component="main" maxWidth="xs" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <CssBaseline />
      <div>
        <Typography component="h1" variant="h5">
          Welcome to Policy Chat
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '24px 0', width: '100%' }}
          onClick={handleLogin}
        >
          Sign In
        </Button>
      </div>
    </Container>
  );
};

export default LoginPage;
