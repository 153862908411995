import React, { FC, useState, useEffect,forwardRef, useImperativeHandle } from 'react';

import './sidebar.css';
import './sidebartoggle.css';
import { useMsal } from '@azure/msal-react';
import { Conversation } from './chatwindow';
import MenuItem from './MenuItem'; // Import your MenuItem component

import { BackdropProps, List } from '@mui/material';

interface SidebarProps {
  emailAddress?: string;
  loadConversation?: (conversation_id: string) => void;
  refresh?:boolean;
  }

// const Sidebar: FC<SidebarProps> = ({ emailAddress, loadConversation,refresh }) => {

  const Sidebar=  forwardRef((props:SidebarProps, ref) => {

    useImperativeHandle(ref, () => ({

      refreshConversations() {
        
        fetch_conversationsM();
      }
  
    }));

  const [cTitles, setCTitles] = useState<string[]>(["New Chat", "What is AI?", "what are the advantages and disadvantages of AI?", "What is Deepmind?"]);

  const [isMenuOpen, setMenuOpen] = useState(true); // Set to true for the menu to be opened by default

  const [isNButtonHandled,setIsNButtonHandled] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const [cons, setCons] = useState<Conversation[]>([])

  const { instance, accounts } = useMsal();
  const userName = instance.getActiveAccount()?.username;
 
  //const baseUrl = "https://citgenai-poc-orch-webapp01.unisys.com/"

  //const baseUrl = "http://localhost:6677/"

  const baseUrl = "https://citgenai-orch-webapp-dev-dvc3cvc7h5eqgwdq.eastus2-01.azurewebsites.net/"

  //const baseUrl = "https://citgenai-poc-orch-app.cit.unisys.com/"

  const fetch_conversationsM =() => {
    if (accounts.length > 0) {
      const request = {
        account: accounts[0],
        scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
      };
      // Retrieve an access token
      instance.acquireTokenSilent(request)
        .then(async response => {
          //alert(baseUrl +'health/');
          const fetch_conversations = await fetch(baseUrl + 'fetch_conversations', {
            //mode:"no-cors",
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${response.accessToken}`,
            },
            body: JSON.stringify({ email: userName })
          }).then((resp) => {
            return resp.json()
          }
          ).catch(err => { console.log(err) });
          console.log(fetch_conversations);

          setCons(fetch_conversations);
          let fcArr: Conversation[] = fetch_conversations;
          let cTitles;

          if (Array.isArray(fcArr)) {
            cTitles = fcArr?.map(x => x.description)
            setCTitles(cTitles);
          }

        });
    }
  }

  useEffect(() => {
    // Fetch initial messages when the component mounts
    //console.log(localStorage);
    let conversation_id;
    if (localStorage.getItem('conversationId') !== null) {
      conversation_id = localStorage.getItem('conversationId')
    }
    else {
      conversation_id = "";
    }
    fetch_conversationsM();
  }, []);


 /*  useEffect(() => {
    fetch_conversationsM(); //children function of interest
  }, [props.refresh]);
 */

  function handleChatDelete(id: string): void {
    if (accounts.length > 0) {
      const request = {
        account: accounts[0],
        scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
      };
      // Retrieve an access token
      instance.acquireTokenSilent(request)
        .then(async response => {
          //alert(baseUrl +'health/');
          const delete_conversation = await fetch(baseUrl + 'delete_conversation', {
            //mode:"no-cors",
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${response.accessToken}`,
            },
            body: JSON.stringify({ conversation_id: id })
          }).then((resp) => {
            return resp.json()
          }
          ).catch(err => { console.log(err) });
          console.log(delete_conversation);
          fetch_conversationsM();
        });
    }
  }

  function handleChatSelect(id: string): void {
    props.loadConversation(id);
  }

  const createConversation = async () => {
    setIsNButtonHandled(false);
    if (accounts.length > 0) {
      const request = {
        account: accounts[0],
        scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
      };
      // Retrieve an access token
      instance.acquireTokenSilent(request)
        .then(async response => {
          //alert(baseUrl +'update_conversation');
          const create_conversation = await fetch(baseUrl + 'create_conversation', {
            //mode:"no-cors",
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${response.accessToken}`,
            },
            body: JSON.stringify({ email: userName})
          }).then((resp) => {

            return resp.json()
          }
          ).catch(err => { console.log(err)
            setIsNButtonHandled(true); });
          console.log(create_conversation);
          props.loadConversation(create_conversation.id);
          fetch_conversationsM();
          setIsNButtonHandled(true);
        });
    }
  }

  function handleChatEdit(id: string, newText: string): void {
    //alert(newText)
    if (accounts.length > 0) {
      const request = {
        account: accounts[0],
        scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
      };
      // Retrieve an access token
      instance.acquireTokenSilent(request)
        .then(async response => {
          //alert(baseUrl +'update_conversation');
          const update_conversation = await fetch(baseUrl + 'update_conversation', {
            //mode:"no-cors",
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${response.accessToken}`,
            },
            body: JSON.stringify({ conversation_id: id, title: newText })
          }).then((resp) => {
            return resp.json()
          }
          ).catch(err => { console.log(err) });
          console.log(update_conversation);

          fetch_conversationsM();
        });
    }
  }





  return (

    <div  className="mbView" /* className="sidebar"  */ style={{height:'445px',borderRight: '1px solid rgb(204, 204, 204)'}} >
      <button className="toggle-button" onClick={toggleMenu}>
        {isMenuOpen ? '☰' : '≡'}
      </button>
      <div className={`mobile-chat-menu ${isMenuOpen ? 'open' : 'closed'}`}>
        <h3 style={{  textAlign: 'left',paddingRight:'75px' }}>Conversations</h3>
        <div style={{  textAlign: 'left',paddingBottom:'5px'}} ><button disabled={!isNButtonHandled} style={{borderRadius: '4px',cursor: 'pointer',padding: '8px'} } onClick={createConversation}>New Chat</button></div>
        {/* <p>Email Address: {emailAddress}</p> */}
        {/*  <ul style={{textAlign:'left',marginLeft:'15px'}}> */}
        <List className="conversation-list">
          {
            (Array.isArray(cons)) ? cons?.map((conv, cIndex) => (
              /*  <li onClick={() => loadConversation(conv.id)} key={cIndex}>{conv.title}</li> */
              <MenuItem
                key={conv.id}
                item={{ id: conv.id, text: conv.description }}
                onDelete={() => handleChatDelete(conv.id)}
                onEdit={(itemId,newText) => handleChatEdit(itemId, newText)}
                onSelect= {(itemId)=> handleChatSelect(itemId)}
              />

            )) : <></>}
          {/* </ul> */}
        </List>
      </div>
    </div>
  );
});

export default Sidebar;