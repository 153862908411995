// Navbar.js

import React, { useState,useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Typography, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import { useMsalAuthentication, useIsAuthenticated, useMsal } from "@azure/msal-react";
import profileImage from '../image/profilepicture.png'
import { loginRequest } from '../authConfig';
import axios, { AxiosInstance } from 'axios';
import { msalInstance } from "../index";
import unisyslogo from '../image/unisys-logo.png'
import Avatar from '@mui/material/Avatar';



const Navbar = ({ onSignOut }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleMenu = (event) => {
    
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const isAuthenticated = useIsAuthenticated();
  const { result, error } = useState(null);

  var profilePicture = new Blob();
  const [imgUrl, setImgUrl] = useState();
  const [UserName, setUserName] = useState();
  
  var Token = null;


  useEffect(() => {
      async function fetchToken() {
          if (isAuthenticated) {
              const account = msalInstance.getActiveAccount();
              const response = await msalInstance.acquireTokenSilent({
                  scopes: ["User.Read", "openid", "profile"],
                  ...loginRequest,
                  account: account
              });
              const accessToken = response.accessToken;
              Token = response;
              setUserName(Token.account.displayName)
              //console.log(response);
              profilePicture = getMyProfilePicture(response);
          }
      }


      fetchToken();
    

  }, [error, result]);


  function getMyProfilePicture(Token) {
    try {
        if (Token)
            var accessToken = Token.accessToken;
        //console.log(accessToken);
        if (accessToken) {
            return getMyPhoto(accessToken);
        }
    }
    catch (err) {
        console.error(err);
    }
}

function getMyPhoto(accessToken) {
  getUserInfo("me", accessToken).then(
    function (response) {
        if (response) {
            return response;
        }
    })
    .then(
        function (response) {
            if (response) {
                setUserName(response.displayName);
            }
        });

    return getUserPhoto("me", accessToken).then(
        function (response) {
            if (response) {
                return response;
            }
        })
        .then(
            function (photoBlob) {
                if (photoBlob) {
                    profilePicture = photoBlob;
                    const reader = new FileReader();
                    reader.readAsDataURL(profilePicture);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        setImgUrl(base64data);
                    };
                }
            });


}

function getUserPhoto(userId, accessToken) {
    let AuthenticatedApi = axios.create({
        baseURL: 'https://graph.microsoft.com/v1.0/me/photo/$value',
        responseType: 'blob',
        headers: {
            Authorization: 'Bearer ' + Token.accessToken,
            'Content-Type': 'application/json'
        }
    });

    return AuthenticatedApi.get()
        .then((response) => {
            //console.log("response User Name1", response);
            //     console.log("Response123");
            //     console.log(response.data);
            //   setUserName(response.name);
            //console.log("response - User Name", response.name);
            return response.data;
        })
        .catch((error) => {
            throw Error('An error has occurred calling the api: ' + error);
        });




}

function getUserInfo(userId, accessToken) {
    let AuthenticatedApiUser = axios.create({
        baseURL: 'https://graph.microsoft.com/v1.0/me',
        //responseType: 'blob',
        headers: {
            Authorization: 'Bearer ' + Token.accessToken,
            'Content-Type': 'application/json'
        }
    });

    return AuthenticatedApiUser.get()
        .then((response) => {
            //console.log("response User Name1", response);
            //console.log("Response User Info");
            //console.log(response.data.displayName);
            setUserName(response.data.displayName);
            //console.log("response - User Name", response.name);
            return response.data;
        })
        .catch((error) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
}

  return (
    <AppBar className="logo-banner" position="static" style={{ backgroundColor: '#003134' }}>
      <Toolbar>
      <img
          src={unisyslogo}
          alt="Logo"
          height={48}
          style={{ borderRadius: '50%' }}
        />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          
        </Typography>

        {/* Add your image here */}
        
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
          <MenuItem onClick={onSignOut}>Sign Out</MenuItem>
        </Menu>
        
         <Stack direction="row" spacing={2 }>
         {UserName ? <Typography sx={{ fontWeight: 'bold',fontSize:'14px',paddingTop:'18px', color: 'white' }}  >{UserName}</Typography> : <></>}
         <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >{imgUrl ? (
                        <Avatar src={imgUrl} size="md" />
                    ) : (
                        <Avatar src={profileImage} size="md" />
                    )}
                    </IconButton>
                   
                </Stack>
        </Toolbar>
    </AppBar>
  );
};

export default Navbar;
