import React, { useState, useEffect, useRef } from 'react';

//import axios from 'axios';
//import Markdown from 'react-markdown'

import Markdown, { compiler } from 'markdown-to-jsx'
import {render} from 'react-dom';
import ReactMarkdown from 'react-markdown'
import Navbar from './Navbar';

import Sidebar from './Sidebar';
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

import rehypeExternalLinks from 'rehype-external-links';

import { useMsal } from '@azure/msal-react';
import apiClient from '../http-common'
import { AuthenticationResult } from '@azure/msal-browser/dist/response/AuthenticationResult';
import './streamingcursor.css';
//const fetch = require('node-fetch');
//import fetch from 'node-fetch';

//import DownArrow from './DownArrow'

import ScrollToBottom from 'react-scroll-to-bottom';
import useDeepCompareEffect from 'use-deep-compare-effect';

export interface Message {
    text: string;
    sender: string;
    email: string;
    conversation_id: string;
    //timestamp: number;
    streamstarted: boolean;
}


export type Role =
    | 'human'
    | 'ai'
    | 'system'
    | 'tool_use'
    | 'user'
    | 'assistant'
    | 'loading'
    | 'done'
    | 'dummy';
export const roles: Role[] = [
    'human',
    'ai',
    'system',
    'tool_use',
    'user',
    'assistant',
    'loading',
    'done',
];

export interface ChatInterface {
    id: string;
    title: string;
    folder?: string;
    messages: MessageInterface[];
    config?: JSON;
    titleSet: boolean;
}

export interface Conversation {
    id: string;
    title: string;
    owner_id: string;
    description: string;
    created_at: Date;
}
export interface MessageInterface {
    conversation_id: string;
    id: number;
    message_text: string;
    message_type: Role;
    created_at: Date;
    streamstarted: boolean;
    /* meta?: MetaInterface;
    events?: EventInterface[]; */
}


const ChatWindow: React.FC = () => {
    const [message, setMessage] = useState<string>('');
    const [messages, setMessages] = useState<Message[]>([]);

    const [conversationId, setConversationId] = useState<string>('');
    const [currentConversation, setCurrentConversation] = useState<Conversation>()

    const [chats, setChats] = useState<MessageInterface[]>([])

    const [newMessage, setNewMessage] = useState<string>('new Message:');
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const divRef = React.useRef<HTMLDivElement>(null)

    const [cursorVisible, setCursorVisible] = useState(true);

    let testMessage: string;

    const { instance, accounts } = useMsal();
    const userName = instance.getActiveAccount()?.username;

    //const baseUrl = "https://citgenai-poc-orch-webapp01.unisys.com/"

    //const baseUrl = "http://localhost:6677/"
    
    const baseUrl = "https://citgenai-orch-webapp-dev-dvc3cvc7h5eqgwdq.eastus2-01.azurewebsites.net/"

    //const baseUrl = "https://citgenai-poc-orch-app.cit.unisys.com/"

    //const [refresh, doRefresh] = useState(0);

    const divBottomRef = React.useRef<HTMLDivElement>(null);

    const childRef = useRef(null);
    const [suspendStream, setSuspendStream] = useState<boolean>(false);

    const sstRef = useRef(false);
    var reader;
    useEffect(() => {
        // Fetch initial messages when the component mounts
        //console.log(localStorage);
        let conversation_id;
        if (localStorage.getItem('conversationId') !== null) {
            conversation_id = localStorage.getItem('conversationId');
        }
        else {
            conversation_id = "";
        }
        setConversationId(conversation_id)
        if (accounts.length > 0) {
            const request = {
                account: accounts[0],
                scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
            };
            // Retrieve an access token
            instance.acquireTokenSilent(request)
                .then(async response => {
                    //alert(baseUrl +'health/');
                    const healthcheck = await fetch(baseUrl + 'health', {
                        //mode:"no-cors",
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'

                        }
                    }).then((resp) => {
                        return resp
                    }
                    ).catch(err => { console.log(err) });
                    console.log(healthcheck);
                    /* if(conversationId != "" && conversationId != undefined)
                    { */
                    let convId;
                    if (conversation_id == undefined || conversation_id == null) {
                        convId = "";
                    }
                    else {
                        convId = conversation_id;
                    }
                    const pushresponse = await fetch(baseUrl + 'initialize', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": `Bearer ${response.accessToken}`,
                        },
                        body: JSON.stringify({ email: userName, conversation_id: convId })
                    }).then((resp) => {
                        return resp.json()
                    }
                    ).catch(err => { console.log(err) });

                    console.log(pushresponse);

                    if (pushresponse) {
                        console.log(pushresponse);
                        setConversationId(pushresponse.id);
                        setCurrentConversation(pushresponse);

                        localStorage.setItem("conversationId", pushresponse.id)
                        // doRefresh(prev => prev + 1)
                        if (childRef.current) {
                            //alert("refresh")
                            childRef.current.refreshConversations();
                        }
                        fetchMessages(pushresponse.id);
                    }

                    /*  } */

                });
        }


    }, []);

    useEffect(() => {
        //alert(chats.length)
        if (chats.length > 0) {

            if (currentConversation.description == "New Chat" && chats.length >=2) {   //alert("in setTitle")
                setTitle(currentConversation.id)
            }

            const newElement = chats[chats.length - 1];
            let newIndex = chats.length - 1;
            console.log('New element added:', newElement);
            if (newElement.message_type === "ai" && newElement.streamstarted == true) {
                //newElement.done = true
                newElement.streamstarted = false;
                let cMessage = message
                setMessage("");
                let cursorElem = document.getElementById("mcursor_" + newIndex);
                if (cursorElem) {
                    cursorElem.style.display = "inline";
                }
                callStream(newElement, newIndex, cMessage);
            }

            //alert("before scroll in initial load")
            if (divBottomRef?.current) {
                divBottomRef?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "end"
                })
            }
            console.log("in chats effect " + newElement.message_text);
        }

    }, [chats]);

   /*  useDeepCompareEffect(() => {
        
    }, [chats]); */

    const scrollToDivBottom = () => {
        if (messages.length > 0) {
            if (divBottomRef?.current) {
                divBottomRef?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "end"
                })
            }
        }
    }
    //axios.defaults.withCredentials = true

    const setTitle = async (convId) => {
        try {
            if (accounts.length > 0) {
                const request = {
                    account: accounts[0],
                    scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
                };
                // Retrieve an access token
                instance.acquireTokenSilent(request)
                    .then(async response => {
                        const setConvTitle = await fetch(baseUrl + 'generate_conversation_title', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${response.accessToken}`,
                            },
                            body: JSON.stringify({ email: userName, conversation_id: convId })
                        }).then(resp => { return resp.json(); });

                        console.log(setConvTitle);
                        if (childRef.current) {
                            childRef.current.refreshConversations();
                        }
                        /*const sortedMessages = fetchresponse.sort(
                            (a: Message, b: Message) => a.timestamp - b.timestamp
                        );
                        setMessages(sortedMessages);*/
                    });
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const get_conversation = async (convId) => {
        try {
            if (accounts.length > 0) {
                const request = {
                    account: accounts[0],
                    scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
                };
                // Retrieve an access token
                instance.acquireTokenSilent(request)
                    .then(async response => {
                        const get_conversation_response = await fetch(baseUrl + 'get_conversation', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${response.accessToken}`,
                            },
                            body: JSON.stringify({ email: userName, conversation_id: convId })
                        }).then(resp => { return resp.json(); });

                        console.log(get_conversation_response);
                        setCurrentConversation(get_conversation_response)
                        /*const sortedMessages = fetchresponse.sort(
                            (a: Message, b: Message) => a.timestamp - b.timestamp
                        );
                        setMessages(sortedMessages);*/
                    });
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    }

    const fetchMessages = async (convId) => {
        try {
            if (accounts.length > 0) {
                const request = {
                    account: accounts[0],
                    scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
                };
                // Retrieve an access token
                instance.acquireTokenSilent(request)
                    .then(async response => {
                        const fetchresponse = await fetch(baseUrl + 'fetch_messages', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${response.accessToken}`,
                            },
                            body: JSON.stringify({ email: userName, conversation_id: convId })
                        }).then(resp => { return resp.json(); });

                        console.log(fetchresponse);
                        setChats(fetchresponse);
                        /*const sortedMessages = fetchresponse.sort(
                            (a: Message, b: Message) => a.timestamp - b.timestamp
                        );
                        setMessages(sortedMessages);*/
                    });
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const getCurrentTimestamp = () => {
        const timestamp = new Date().getTime(); // getTime() returns the timestamp in milliseconds
        return timestamp;
    };

   /*  function Markdown({ text, inline }) {
        const overrides = inline;
        return compiler(text, { overrides, forceBlock: true });
      } */
    const callStream = async (botMessage, mIndex, cMessage) => {

        try {
            if (accounts.length > 0) {
                const request = {
                    account: accounts[0],
                    scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
                };
                // Retrieve an access token
                instance.acquireTokenSilent(request)
                    .then(async response => {

                        const newArray = [...chats];
                        var streamresponse = await fetch(baseUrl + 'stream_chat', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${response.accessToken}`
                            },
                            body: JSON.stringify({ "content": cMessage, "email": userName, "conversation_id": conversationId })
                            //body: JSON.stringify({ "content": cMessage, "user_id":"92fe8796-9a45-4385-b9cd-000b4dda42de" })
                        }).catch(error => {
                            //alert("in callstream stream response")
                            setSubmitDisabled(false);
                            console.log(error);
                            let cursorElem = document.getElementById("mcursor_" + mIndex);
                            if (cursorElem) {
                                cursorElem.style.display = "none";
                            }
                            if (mIndex !== -1) {
                                // Step 4: Update the newly added element
                                //alert("in done")
                                newArray[mIndex] = { ...newArray[mIndex], message_text: "not able to connect, please try again!!", streamstarted: false };
                                setChats(newArray);
                            }
                            return;
                        })

                        if (streamresponse && streamresponse.body) {
                            reader = streamresponse?.body?.getReader();

                            var decoder = new TextDecoder('utf-8');
                            // Step 1: Clone the array



                            reader?.read().then(function processResult({ done, value }) {

                                if (done) {
                                    setSubmitDisabled(false);
                                    console.log("stream is closed");
                                    // let i = 0;
                                    // let wInt = window.setInterval(() => {
                                    //     i++;
                                    //     if (i == 2) {
                                    //         window.clearInterval(wInt);
                                    //     }
                                    //     //alert(" in interval");
                                    //     if (mIndex !== -1) {

                                        //const jsxElem =   compiler(botMessage.message_text, { wrapper: null })
                                       /*  const jsxElem = <Markdown
                                        options={{
                                            overrides: {
                                              a: {
                                                component: AnchorTag                                                
                                              },
                                            },
                                          }}
                                        
                                         >
                                            {botMessage.message_text}
                                        </Markdown> 
                                        botMessage.message_text = botMessage.message_text.trim();
                                        console.log(botMessage.message_text);
                                        const htmlElem = render(jsxElem,document.getElementById("divHtml"));
                                        const htmlString =document.getElementById("divHtml").innerHTML;
                                         console.log(htmlString)
                                         console.log("jsxElem");
                                         console.log(jsxElem); 
                                            // Step 4: Update the newly added element
                                            //alert("in done");
                                            newArray[mIndex] = { ...newArray[mIndex], message_text: htmlString, streamstarted: false };
                                            setNewMessage(htmlString);
                                            setChats(newArray); */
                                            /* newArray.push({
                                                message_text: '', streamstarted: false, message_type: 'dummy',
                                                conversation_id: '',
                                                id: 0,
                                                created_at: undefined
                                            }) */

                                            /* setChats((prevArray: MessageInterface[]) => [...prevArray, {
                                                message_text: '', streamstarted: false, message_type: 'dummy',
                                                conversation_id: '',
                                                id: 0,
                                                created_at: undefined
                                            }]); */
                                            //setChats(newArray);
                                            //setNewMessage(botMessage.message_text + " m");
                                            //alert("in after");
                                            //console.log(newArray[mIndex])
                                    //     }
                                    // }, 3000)


                                    //setCursorVisible(false)
                                    let cursorElem = document.getElementById("mcursor_" + mIndex);
                                    if (cursorElem) {
                                        cursorElem.style.display = "none";
                                    }
                                    return;
                                }


                                if (sstRef?.current == true) {
                                    reader.cancel();
                                    setSubmitDisabled(false);
                                    console.log("stream is closed");
                                    let cursorElem = document.getElementById("mcursor_" + mIndex);
                                    if (cursorElem) {
                                        cursorElem.style.display = "none";
                                    }
                                    return;
                                }


                                let token = decoder.decode(value);
                                console.log(token)
                                //alert(token);
                                //alert(value)
                                console.log(token);
                                if (token.endsWith('.') || token.endsWith('!') || token.endsWith('?')) {
                                    botMessage.message_text += token + ' '
                                }
                                else {
                                    botMessage.message_text += token
                                }
                                setNewMessage(botMessage.message_text);
                                if (mIndex !== -1) {
                                    // Step 4: Update the newly added element
                                    newArray[mIndex] = { ...newArray[mIndex], message_text: botMessage.message_text, streamstarted: false };

                                    // Step 5: Update state

                                    setChats(newArray);
                                    if (divBottomRef?.current) {
                                        divBottomRef?.current?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "end"
                                        })
                                    }
                                }

                                return reader?.read().then(processResult);
                            }).catch(error => {
                                //alert("in catch one");
                                setSubmitDisabled(false);
                                let cursorElem = document.getElementById("mcursor_" + mIndex);
                                if (cursorElem) {
                                    cursorElem.style.display = "none";
                                }
                                console.log(error);


                            }).finally(() => {
                                //alert("in final one");
                                let cursorElem = document.getElementById("mcursor_" + mIndex);
                                if (cursorElem) {
                                    cursorElem.style.display = "none";
                                }
                                setSubmitDisabled(false);

                                sstRef.current = false;



                            });
                        }
                    });
            }
        }
        catch (e) {
            //alert("in catch two");
            setSubmitDisabled(false);
            let cursorElem = document.getElementById("mcursor_" + mIndex);
            if (cursorElem) {
                cursorElem.style.display = "none";
            }
        }
        finally {
            //alert("in finally two");
            //alert("in last final")

        }
    }

    const get_time = async (token) => {
        //alert("in get time")
        const healthcheck = await fetch(baseUrl + 'get_time', {
            //mode:"no-cors",
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json'

            }
        }).then((resp) => {
            return resp.json()
        }
        ).catch(err => { console.log(err) });
        console.log(healthcheck);
        return healthcheck;
    }

    const sendMessage = async () => {
        if (accounts.length > 0) {
            const request = {
                account: accounts[0],
                scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
            };
            // Retrieve an access token
            instance.acquireTokenSilent(request)
                .then(async response => {

                    let clientcreatedtime;
                    let botcreatedtime;

                    clientcreatedtime = await get_time(response.accessToken);
                    console.log(clientcreatedtime)
                    console.log("client created time" + clientcreatedtime);
                    let clientMessage: MessageInterface = {
                        "conversation_id": conversationId, "id": 0,
                        "message_text": message, message_type: "human", "created_at": new Date(clientcreatedtime)
                    } as MessageInterface;


                    setChats((prevArray: MessageInterface[]) => [...prevArray, clientMessage]);

                    botcreatedtime = await get_time(response.accessToken);

                    var normalresponse = await fetch(baseUrl + 'normal_chat', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": `Bearer ${response.accessToken}`
                        },
                        body: JSON.stringify({ "content": message, "email": userName, "conversation_id": conversationId })
                    }).then(resp => { return resp.json() });
                    console.log(normalresponse)
                    let botMessageObj = {
                        "conversation_id": conversationId, "id": 0,
                        "message_text": normalresponse, message_type: "ai", "created_at": new Date(botcreatedtime), streamstarted: true
                    } as MessageInterface;

                    setChats((prevArray: MessageInterface[]) => [...prevArray, botMessageObj]);
                    //console.log("normal");



                });


        }
    }

    const sendAsyncMessage = async () => {
        if (message?.trim() == "")
            return;
        try {


            setSubmitDisabled(true);

            if (accounts.length > 0) {
                const request = {
                    account: accounts[0],
                    scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
                };
                // Retrieve an access token
                instance.acquireTokenSilent(request)
                    .then(async response => {

                        let clientcreatedtime;
                        let botcreatedtime;

                        let currentIndex = messages.length + 1;


                        clientcreatedtime = await get_time(response.accessToken);
                        console.log(clientcreatedtime)
                        console.log("client created time" + clientcreatedtime);
                        let clientMessage: MessageInterface = {
                            "conversation_id": conversationId, "id": 0,
                            "message_text": message, message_type: "human", "created_at": new Date(clientcreatedtime)
                        } as MessageInterface;


                        setChats((prevArray) => {
                            /* console.log(prevArray);
                            alert(prevArray); */
                            return [...(Array.isArray(prevArray) ? prevArray : []), clientMessage]
                        });

                        botcreatedtime = await get_time(response.accessToken);
                        let botMessageObj = {
                            "conversation_id": conversationId, "id": 0,
                            "message_text": "", message_type: "ai", "created_at": new Date(botcreatedtime), streamstarted: true
                        } as MessageInterface;


                        setChats((prevArray: MessageInterface[]) => [...prevArray, botMessageObj]);
                        /* setChats((prevArray) => {
                         return [...(prevArray ? []: prevArray), clientMessage]
                     }); */
                        //setCursorVisible(true);

                    });
            }

        }
        catch (e) {
            //alert("in sendasync catch one");
            setSubmitDisabled(false);

        }
    }

    const addConversationTitle = async () => {
        if (accounts.length > 0) {
            const request = {
                account: accounts[0],
                scopes: ["api://45ac988a-4d61-4a35-8e1b-c1768e7b4435/api.clientaccess", "User.Read"]
            };
            // Retrieve an access token
            instance.acquireTokenSilent(request)
                .then(async response => {

                    //alert("with token in add conversation")
                    const addTitleResponse = await fetch(baseUrl + 'generate_conversation_title', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": `Bearer ${response.accessToken}`,
                        },
                        body: JSON.stringify({ email: userName, conversation_id: conversationId })
                    }).then(resp => { return resp.json(); });
                });

        }

    }

    const handleSignOut = async () => {
        // Implement your sign-out logic here
        //console.log('User signed out');

        //const response = await apiClient.get("/clearSession?email=" + userName,{withCredentials:true});
        // Sort messages by timestamp (earliest first)
        //alert("in conversation")
        //await addConversationTitle();

        localStorage.removeItem('conversationId');
        instance.logoutRedirect();
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            //sendMessage();
            if (message?.trim() != "") {
                sendAsyncMessage();
            }

            //sendMessageWithStream();
        }
    };
    let msgTxt: string = "";

    const loadParentConversation = (conversation_id: string) => {
        console.log("parent method is called");

        if (conversation_id != "" && conversation_id != undefined) {
            setConversationId(conversation_id);
            get_conversation(conversation_id)
            localStorage.setItem("conversationId", conversation_id)
            fetchMessages(conversation_id);
        }

    }

    function stopMessaging(): void {
        //alert("in stop stream");
        //setSuspendStream(true);

        sstRef.current = true;

    }
    const AnchorTag = ({  ...props }) =>{
        console.log(props);
        if(props.href != null)
        {
            props.href = props.href.trim();
        }
        
      return  (<a target="_blank" {...props} />)
    } 
    return (
        <div style={{ maxWidth: '1200px', height: '600px', margin: 'auto', padding: '0px'/* , border: '1px solid rgb(204, 204, 204)' */ }}>
            <Navbar onSignOut={handleSignOut} />
            {/* <ScrollToBottom  className="chat-container"> */}

            <div>
                <div className="app-container" style={{ border: '1px solid rgb(204, 204, 204)', display: 'flex', flexDirection: 'row' }}>
                    <Sidebar ref={childRef} emailAddress={userName} loadConversation={loadParentConversation} />
                    <div className="scroll-container"
                        style={{ overflowY: 'auto', width: '100%', maxHeight: '445px', padding: '5px' }}
                    >
                        {/* <div style={{ height: '100%',padding:'5px' }}> */}
                        {(Array.isArray(chats) ? chats.map((msg, index) => (
                            (msg.message_type != 'dummy' ?
                                (<div

                                    key={index}
                                    style={{
                                        textAlign: msg.message_type === 'human' ? 'right' : 'left',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <small style={{ fontSize: '8px' }}>
                                        {new Date(msg.created_at + "Z").toLocaleDateString()} {new Date(msg.created_at + "Z").toLocaleTimeString()}
                                    </small>
                                    <br />
                                    <span
                                        style={{
                                            padding: '5px',
                                            borderRadius: '8px 8px 8px 8px',
                                            display: 'inline-grid',
                                            lineHeight: '25px',
                                            background: msg.message_type === 'human' ? '#5cb85c' : '#eee',
                                            color: msg.message_type === 'human' ? '#fff' : '#333',
                                            //color: '#333'
                                        }}
                                    >


                                        <Markdown
                                        options={{
                                            overrides: {
                                              a: {
                                                component: AnchorTag                                                
                                              },
                                            },
                                          }}
                                        
                                         >
                                            {msg.message_text}
                                        </Markdown>
                                        {/*  {cursorVisible && <span className="cursor">|</span>} */}
                                        <span id={`mcursor_${index}`} style={{ display: 'none' }} className="cursor">|</span>
                                    </span>

                                </div>) : <></>)

                        )) : <></>)}
                        {/* </div> */}
                        <div ref={divBottomRef} />
                    </div>



                </div>
                {/* <button onClick={scrollToDivBottom}>
          
                </button> */}
                <div style={{ marginTop: '20px' }}>
                    <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        style={{ width: '70%', padding: '8px' }}
                        disabled={submitDisabled}
                    />
                    {/* <button onClick={sendAsyncMessage} disabled={submitDisabled} >
                    Send Message
                </button> */}
                    {submitDisabled ? (
                        <button onClick={stopMessaging} className="messagebutton">Stop</button>
                    ) : (
                        <button onClick={sendAsyncMessage} className="messagebutton">Send Message</button>
                    )}
                </div>
            </div>
            <div id="divHtml" style={{display:'none'}}></div>
        </div>
    );
};

export default ChatWindow;
