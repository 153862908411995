import logo from './logo.svg';
import './App.css';
import {AuthenticatedTemplate,UnauthenticatedTemplate,useMsal,MsalProvider} from '@azure/msal-react'
import { loginRequest } from './authConfig';
import ChatWindow from './components/chatwindow.tsx'
import LoginPage from './LoginPage';

function WrappedView()  {
  const {instance} = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    instance
    .loginRedirect({
      ...loginRequest,
      prompt:'create'
    })
    .catch((error) => console.log(error));
  };

  return (
    <div className ="App">
      <AuthenticatedTemplate>
        {activeAccount ? (
          <ChatWindow></ChatWindow>
        ):null}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
       <LoginPage></LoginPage>
      </UnauthenticatedTemplate>
      
    </div>
  );


};



function App ({instance})  {
  return (
    <MsalProvider instance={instance}>
        <WrappedView/>
    </MsalProvider>
  )
};



/*
function App ()  {
  return (
    <ChatWindow></ChatWindow>
  )
};
*/

export default App;
